<template>
	<div class="about">
		<div class="top-about">
			<div class="content-container">
				
			</div>
		</div>
		<div class="middle">
			<div class="m-bg-full">
				<div class="content-container m-about-us">
					<div class="m-about">
						<div class="title">关于我们</div>
						<div class="content">
							<p>
								深圳友邦物联科技有限公司成立于2015年，是电商供应链优化解决方案提供商，业务范围涵盖全球邮政及快递产品开发代理运营、供应链智能制造研发、软件开发、图像识别算法、电商物流操作中心整体运营等；拥有邮政、国际快递、国际航空运输、供应链软硬件创新研发及系统集成、劳务服务平台等丰富经验。
							</p>
							<p>
								友邦物联坚持以持续技术创新为客户不断创造价值、降低成本、提升行业竞争力为己任，凭借不断增强的创新能力，突出的灵活定制能力、流程完善的服务能力赢得客户的信任和合作。
							</p>
							<p>
								未来，我们将本着为用工企业和第三方劳务派遣企业降低成本、提高效率、降低差错率的目的，继续致力于供给侧和需求侧的优化服务，应对全球贸易领域日新月异的挑战。
							</p>
							<p>
								公司地址：深圳市龙岗区平湖街道华南城
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								邮箱：yopoun@yopoun.com
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								电话：0755-89339362
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="m-bg-grey"></div>
			<div class="content-container m-join">
				<div>
					<div class="title">加入我们</div>
				</div>
				<div class="columns m-join-columns">
					<div class="column is-half">
						<div class="m-card">
							<div class="title">Java 高级工程师</div>
							<div class="content">
								1、3年以上软件研发经验、1年以上系统分析和设计经验；<br />
								2、精通java语言；熟悉J2EE技术架构;<br />
								3、熟练SSH和SpringMvc，Spring boot、 Spring cloud等框架；<br />
								4、精通高可用、高并发分布式系统设计研发；<br />
								5、熟悉SQL、NoSQL
								等主流数据库，熟练使用redis、消息队列等常用中间件；<br />
								6、有良好的编程风格，能够书写规范、优质的代码；<br />
								7、良好的团队协作能力；<br />
								8、思路清晰、善于思考、能独立分析和解决问题。<br />
								<br />

								<p>欢迎发送你的简历到 yopoun@yopoun.com</p>
							</div>
						</div>
					</div>
					<div class="column is-half">
						<div class="m-card float-r">
							<div class="title">机电工程师</div>
							<div class="content">
								1、熟悉PLC等程序编写、调试；<br />
								2、熟练使用CAD、Visio、office、SolidWorks等；<br />
								3、对待工作认真负责、勤奋务实、善于思考和创新
								具有良好的组织协调能力、管理能力、应变能力，具有良好的心理素质；<br />
								4、具有交叉带设计安装调试经验的优先；<br />
								5、具有非标件设备设计开发经验者和创新思路者优先；<br />
								6、能力突出者，薪资面议。<br />
								<br />
								<p>欢迎发送你的简历到 yopoun@yopoun.com</p>
							</div>
						</div>
					</div>
				</div>
				<div class="columns m-join-columns">
					<div class="column is-half">
						<div class="m-card">
							<div class="title">前端工程师</div>
							<div class="content">
								负责前端UI开发工作。 <br />
								任职资格：<br />
								1、本科及以上学历；<br />
								2、开发基于 HTML5 技术的可灵活定制、可扩展的前端 UI 组件；<br />
								3、优化前端架构，提高系统的灵活性和可扩展性；<br />
								4、熟练掌握前端技术，能独立工作，3 年以上 VUE.js 或 React.js
								项目经验，至少用 VUE 或 React 开发并上线一个产品；<br />
								5、熟练掌握 H5, CSS3, ES6, Webpack 等前端基本技能；<br />
								6、有 Layui 应用开发经验优先；<br />
								7、有手机 APP 工作经验优先。<br />
								<br />
								<p>欢迎发送你的简历到 yopoun@yopoun.com</p>
							</div>
						</div>
					</div>
					<div class="column is-half">
						<div class="m-card float-r">
							<div class="title">安检员</div>
							<div class="content">
								1、熟练掌握各种安检机设备的操作及识别方法，
								掌握图像识别，易燃易爆等危险品的外部特征，及时准确观察，识别可疑物；<br />
								2、遵守各项法律法规和安检各项规章制度，服从各级领导管理，对违反法律法规或安检规章制度的现象应与拒绝并及时向上级报告。<br />
								<br />
								<p>欢迎发送你的简历到 yopoun@yopoun.com</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="foot">
			<div class="f-content">
				Copyright 深圳友邦物联科技有限公司
				<a href="http://www.beian.miit.gov.cn/">粤ICP备17066262号-1</a>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'About',
	components: {},
	methods: {
		clickHome() {
			this.$router.push({ name: 'Home' })
		},
	},
}
</script>
<style scoped>

.f-content {
    margin-top: 153px;
    text-align: center;
    padding: 0 0 46px 0;
    line-height:15px;
    font-size:14px;
    color:rgba(153,153,153,1);
    letter-spacing: 1px;
}
.f-content a,.f-content a:hover {
    color:rgba(153,153,153,1);
    text-decoration: none;
}
.content-container {
	position: relative;
	max-width: 1200px;
	margin: auto;
}

.top-about {
	width: 100%;
	height: 400px;
	background: url('~@/assets/about_top.png') no-repeat;
	background-size: cover;
	position: relative;
}

.middle {
	font-size: 14px;
	width: 100%;
	padding: 0 0 64px 0;
}
.m-about {
	margin: auto;
	margin-top: -110px;
	background: url('~@/assets/about_map.png') no-repeat;
	background-size: cover;
	padding: 0 0 60px 0;
	border-radius: 8px;
}
.m-about .title {
	height: 45px;
	font-size: 32px;
	line-height: 45px;
	text-align: center;
	margin-bottom: 24px;
	padding: 42px;
	font-weight: 700;
}
.m-about .content {
	color: rgba(102, 102, 102, 1);
	text-align: left;
	padding: 0 64px;
	line-height: 24px;
}
.m-bg-grey {
	height: 80px;
	background-color: rgba(249, 251, 253, 1);
}
.m-join .title {
	font-size: 32px;
	line-height: 32px;
	text-align: center;
	padding: 60px 0 20px 0;
	font-weight: bold;
}
.m-card {
	max-width: 482px;
	padding: 40px 40px 40px 40px;
}
.m-card:hover {
	box-shadow: 0px 20px 43px 0px rgba(230, 230, 230, 0.5);
}
.m-card .title {
	margin: 0 0 16px 0;
	padding: 0;
	text-align: left;
	font-size: 18px;
	line-height: 25px;
	height: 25px;
	color: rgba(51, 51, 51, 1);
}
.m-card .content {
	color: rgba(102, 102, 102, 1);
}
.m-bg-full {
	width: 100%;
	background-color: rgba(249, 251, 253, 1);
	z-index: -1;
}
.m-about-us {
	z-index: 20;
}
.footer {
	padding: 0;
	background-color: rgba(255, 255, 255, 1);
}
.f-content {
	margin-top: 0;
	text-align: center;
	padding: 52px 0 46px 0;
	line-height: 15px;
	font-size: 14px;
	color: rgba(153, 153, 153, 1);
	letter-spacing: 1px;
}
.f-content a,
.f-content a:hover {
	color: rgba(153, 153, 153, 1);
	text-decoration: none;
}
</style>
